import axios from 'axios';

const instance = axios.create({
    baseURL: "https://narayana.theflak.in/",
    headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json'
    }
})

export default instance;