import { Switch, Route, Redirect } from "react-router-dom";
import './App.css';
import Jee from './pages/JEE';
import Neet from './pages/NEET';
import FoundationCoaching from './pages/FoundationCoaching';


function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path='/' component={Jee} />
        <Route exact path='/JEE' component={Jee} />
        <Route exact path='/NEET' component={Neet} />
        <Route exact path='/FoundationCoaching' component={FoundationCoaching} />
      </Switch>
    </div>
  );
}

export default App;
