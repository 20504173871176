import React from 'react'

function nacstCard(props) {
    return (
        <div className="nacstCard">
        <div className="nacstCardContent">
        <h4>{props.title}</h4>
        <p>{props.content}</p>
        </div>
    </div>
    )
}

export default nacstCard
