import React from 'react';
import fb from './../assets/facebook.png';
import ig from './../assets/insta.png';

function Footer() {
    return (
        <section id='footer'>
            <div className="container-fluid">
                <div className="webFooter row" style={{textAlign:"center", paddingTop: "60px"}}>
                    <div className="col-4">
                        <a target='_blank' className="footerLink" href="http://www.narayanadelhi.com/refund-policy.php"> Terms & Conditions </a>
                    </div>
                    <div className="col-4" style={{marginTop:"20px"}}>
                    <a style={{marginRight:"10px"}} target='_blank' className="footerLink" href="https://www.facebook.com/The-Narayana-Coaching-105927408313736"> <img src={fb} /> </a>
                    <a target='_blank' className="footerLink" href="https://www.instagram.com/thenarayanacoaching/"> <img src={ig} /> </a>
                    </div>
                    <div className="col-4">
                    <a target='_blank' className="footerLink" href="http://www.narayanadelhi.com/privacy-policy.php"> Privacy Policy</a>
                    </div>
                    <div className="col-12">
                        <div className="contactinfo" style={{display:"inline-grid"}}>
                        <a href="tel:1800-2029-182" style={{marginTop:"30px", fontWeight:"700"}} className="footerLink"> Contact: 1800-2029-182 </a>
                        <a href="mailto:delhi@narayanagroup.com" style={{marginTop:"5px", fontWeight:"700"}} className="footerLink"> Email: delhi@narayanagroup.com </a>
                        <p style={{margin: "15px 0 5px 0", fontSize:"17px", fontWeight:"600"}}> NARAYANA ACADEMY DELHI NCR</p>
                        <p style={{marginTop: "0px", fontWeight:"600"}}> Dwarka | Faridabad | Gurgaon | Janakpuri | Kalu Sarai | Mayur Vihar | Noida | Punjabi Bagh </p>
                        <p style={{marginTop: "10px", fontWeight:"600"}}> © NARAYANA ACADEMY. All Rights Reserved. </p>
                        </div>
                    </div>
                </div>
                <div className="mobFooter row" style={{textAlign:"center", paddingTop:"20px"}}>
                    <div className="col-4">
                        <a target='_blank' className="footerLink" href="http://www.narayanadelhi.com/refund-policy.php"> Terms & Conditions </a>
                    </div>
                    <div className="col-4" style={{marginTop:"10px"}}>
                    <a style={{marginRight:"10px"}} target='_blank' className="footerLink" href="https://www.facebook.com/The-Narayana-Coaching-105927408313736"> <img src={fb} /> </a>
                    <a target='_blank' className="footerLink" href="https://www.instagram.com/thenarayanacoaching/"> <img src={ig} /> </a>
                    </div>
                    <div className="col-4" style={{marginTop:"10px"}}>
                    <a target='_blank' className="footerLink" href="http://www.narayanadelhi.com/privacy-policy.php"> Privacy Policy </a>
                    </div>
                    <div className="col-12">
                        <div className="contactinfo" style={{display:"inline-grid"}}>
                        <a href="tel:1800-2029-182" style={{marginTop:"30px", fontWeight:"700"}} className="footerLink"> Contact: 1800-2029-182 </a>
                        <a href="mailto:delhi@narayanagroup.com" style={{marginTop:"5px", fontWeight:"700"}} className="footerLink"> Email: delhi@narayanagroup.com </a>
                        <p style={{margin: "25px 0 5px 0", fontSize:"17px", fontWeight:"600"}}> NARAYANA ACADEMY DELHI NCR</p>
                        <p style={{marginTop: "0px", fontWeight:"600"}}> Dwarka | Faridabad | Gurgaon | Janakpuri | Kalu Sarai | Mayur Vihar | Noida | Punjabi Bagh </p>
                        <p style={{marginTop: "10px", fontWeight:"600"}}> © NARAYANA ACADEMY. All Rights Reserved. </p>
                        </div>
                    </div>
                </div>
            </div >
        </section >

        
    )
}

export default Footer
