import React from 'react'
import './common.css';
import logo from './../assets/logo.png';
import { Link, animateScroll as scroll } from "react-scroll";

export default function Navbar() {
    return (
        <div className="navbarContainer">
            <div className="logo">
            <img src={logo} />  
            </div>
            <nav className="navbarItem">
                <ul className="navbar-list">
                    <Link
                        activeClass="active"
                        to="nacst"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}>N-ACST</Link>
                    <Link
                        activeClass="active"
                        to="courses"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Courses</Link>
                    <Link
                        activeClass="active"
                        to="results"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Results</Link>
                    <Link
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Contact us</Link>
                </ul>
            </nav>
        </div>
    )
}
