import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import { Provider as AlertProvider } from 'react-alert';
// import AlertTemplate from 'react-alert-template-basic';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

const options = {
  position: 'bottom center',
  timeout: 5000,
  transition: 'fade',
  offset: '10px',
  transition: 'scale',
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={{background: 'rgba(0,0,0,0.9)', color: '#fff', width: '100%', padding: '10px'}}>
    {options.type === 'info'}
    {options.type === 'success'}
    {options.type === 'error'}
    {message}
  </div>
)

ReactDOM.render(
  <Router>
  <React.StrictMode>
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
  </React.StrictMode>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
