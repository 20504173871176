import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDzNiu6e3zvNOEjzA9sBCruOPIHjKBxFHc",
  authDomain: "narayana-74229.firebaseapp.com",
  projectId: "narayana-74229",
  storageBucket: "narayana-74229.appspot.com",
  messagingSenderId: "866465816020",
  appId: "1:866465816020:web:01f5d70fd6ba505ebcca41",
  measurementId: "G-DGRTGCML7G",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;