import React from 'react'
import NacstCard from '../common/NacstCard';
import eligibility from './../assets/1.png';
import exam from './../assets/2.png';
import duration from './../assets/3.png';
import timing from './../assets/4.png';
import './component.css';

function NACST() {
    return (
        <section id='nacst'>
            <div className="container-fluid">
                <div className="row pdtb">
                    <div className="col-12">
                        <h2 className='heading'>N-ACST</h2>
                        <h3 className='heading'>(Admission cum Scholarship Test)</h3>
                        <p className='headingContent'>To motivate, reward and financially assist the deserving and meritorious students, Narayana<br />
                        Group offers N-ACST program, giving students a chance to win a scholarship of up to 90%.</p>
                    </div>
                    <div className="col-6">
                        <div className="nacst">
                        <img src={eligibility} />
                        <NacstCard title='Eligibility' content='Applicable for the students from standards VI to XII' />
                        </div>
                    </div>
                    <div className="col-6">
                    <div className="nacst">
                        <img src={exam} />
                        <NacstCard title='Exam Fees' content='₹200/-' />
                        </div>
                    </div>
                    <div className="col-6">
                    <div className="nacst">
                        <img src={duration} />
                        <NacstCard title='Exam Duration' content='Online exam duration - 60 minutes ' />
                        </div>
                    </div>
                    <div className="col-6">
                    <div className="nacst">
                        <img src={timing} />
                        <NacstCard title='Exam Timings' content='4 pm to 5 pm' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NACST
