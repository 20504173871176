import React from 'react'

function CoursesCard(props) {
    return (
        <div className="coursesCard">
        <div className="CoursesCardContent">
        <h4>{props.title}</h4>
        <p>{props.content}</p>
        </div>
    </div>
    )
}

export default CoursesCard;
