import React, { useState, useEffect } from "react";
import './../components/component.css';
import Navbar from './../common/Navbar';
import NACST from './../components/NACST';
import CoursesCard from '../common/CoursesCard';
import backgroundImage from './../assets/main.png';
import backgroundImageMob from './../assets/mm.png';
import video from './../assets/video.jpg';
import quotesmall from './../assets/quotesmall.png';
import user from './../assets/user.png';
import orangequote from './../assets/orangequote.png';
import foundation from './../assets/foundation.jpg';
import iit from './../assets/iit.jpg';
import Footer from './../common/Footer';
import axios from '../axios';
import firebase from '../firebase';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { useAlert } from 'react-alert';

function Neet() {
    const alert = useAlert()
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [email, setEmail] = useState('');
    const [center, setCenter] = useState('');
    const [standard, setStandard] = useState('');
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [confirmotp, setConfirmotp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [otp, setOtp]  = useState('');
  const [verified, setVerified] = useState(true);
  const [PhoneDisabled, setPhoneDisabled] = useState(false);
  const [disableOTPButton, setDisableOTPBUtton] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

    const setUpRecaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              onSignInSubmit();
            },
          }
        );
      };
    
      const onSignInSubmit = (e) => {
        e.preventDefault();
        // firebase.auth().settings.appVerificationDisabledForTesting = true;
        setUpRecaptcha();
        const phoneNumber = `+91${phone}`;
        const appVerifier = window.recaptchaVerifier;
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // alert.success(<div style={{ color: '#eee' }}>OTP sent Sucesfully</div>);
            setConfirmotp(true);
            console.log(confirmationResult)
            window.confirmationResult = confirmationResult;
            // ...
          })
          .catch((error) => {
            // Error; SMS not sent
            // ...
            console.log(error)
            alert.success(<div style={{ color: '#eee' }}>{error.message}</div>);
          });
      };
    
      const onSendOtp = (e) => {
        const code = otp;
        let optConfirm = window.confirmationResult;
        optConfirm.confirm(code).then((result) => {
          // User signed in successfully.
          setVerified(false);
          setPhoneDisabled(true);
          setSubmitDisabled(true);
          // alert.success(<div style={{ color: '#eee' }}>Verified</div>);
          setError('');
          setMsg(`OTP verified. Please 'Submit' the form`)
          const user = result.user;
          // ...
        }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          setOtp('');
          setError('Invalid OTP');
          // alert.success(<div style={{ color: '#eee' }}>Invalid OTP</div>);
        });
      };
    
    const dataSubmit = async (e) => {
        e.preventDefault();
        const formData = { name, phone, email, center, standard, course:'NEET' }
        try {
            setMsg('');
        setError('');
            setDisabled(true);
            const res = await axios.post("/api/formdata", formData, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });

            if (res) {
                setDisabled(false);
                setName('');
                setPhone('');
                setEmail('');
                setCenter('');
                setStandard('');
                setConfirmotp(false);
                setOtp('');
                setVerified(true);
                setPhoneDisabled(false);
                setDisableOTPBUtton(false)
                setSubmitDisabled(false);
                // setMsg('');
                // setError('');
                // console.log(res.data.message);
                // alert.success(<div style={{ color: '#eee' }}>{res.data.message}</div>);
                setMsg('Form submitted successfully');
            }
        } catch (err) {
            if (err && err.response) { alert.success(<div style={{ color: '#eee' }}>{err.response.data.message}</div>);};
            setDisabled(false);
        }
    }

    const checkContact = async () => {
        try {
        const response = await axios.post(`/api/check/phone`, {course: 'NEET', phone}, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        // console.log(res.data.exists)
        if (response) {
          setPhone('');
          alert.success(<div style={{ color: '#eee' }}>{response.data.message}</div>);
        }}catch (err) {
          console.log('yeee')
          setDisableOTPBUtton(true);
         }
      };
    
      const checkEmail = async () => {
        const response = await axios.post(`/api/check/email`,  {course: 'NEET', email}, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        // console.log(res.data.exists)
        if (response) {
          console.log(response)
          setEmail('');
          alert.success(<div style={{ color: '#eee' }}>{response.data.message}</div>);
        }
      };

    const fetchdata = async () => {
        let mounted = true;
        try {
            const response = await axios.get(`/api/getcenters`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            if (mounted) {
                setData(response.data.centers)
            }
        } catch (err) {
            console.log(err);
        }
        return () => {
            mounted = false
        };
    }

    useEffect(() => {
        fetchdata();
    }, [refresh]);

    return (
        <>

            <Navbar />

            <section id="main" className="mainSection">
                <div className="container-fluid">
                    <div className="row pdtb">
                        <div className="bgMain">
                            <img src={backgroundImage} />
                        </div>
                        <div className="bgMainMob">
                            <img src={backgroundImageMob} />
                        </div>

                        <div className="col-6">
                            <h1 className='heading'>Thrive for <span style={{ color: "#F48120" }}> excellence </span></h1>
                            <p className="headingContent">Every year, Narayana NEET coaching brings out top performers, marking itself as the best and the top coaching institute for AIIMS, NEET (UG).</p>
                            <div className="form">
                                <h3 style={{ fontWeight: "700" }}>Request a call back</h3>
                                <form onSubmit={dataSubmit}>
                  <div className="inputWrapper">
                    <input type="text" placeholder="Name*" value={name} onChange={(e) => setName(e.target.value)} required />
                  </div>
                  <div className="inputWrapper">
                    <input type="email" placeholder="Email*" value={email} onChange={(e) => setEmail(e.target.value)} required onBlur={checkEmail}/>
                  </div>
                  <div className="inputWrapper">
                    <select value={standard} onChange={(e) => setStandard(e.target.value)} required={true}>
                      <option value="" disabled>Choose your current class*</option>
                      <option value="6">6th </option>
                      <option value="7">7th </option>
                      <option value="8">8th </option>
                      <option value="9">9th </option>
                      <option value="10">10th </option>
                      <option value="11">11th </option>
                      <option value="12">12th </option>
                    </select>
                  </div>
                  <div className="inputWrapper">
                    <select placeholder="Choose your nearest center*" value={center} onChange={(e) => setCenter(e.target.value)} required >
                      <option value="" disabled>Choose your nearest center*</option>
                      {data.map((item) => {
                        return (<option value={item.centerName} key={item._id}>{item.centerName}</option>)
                      })}
                    </select>
                  </div>
                  <div className="inputWrapper">
                    <input type="tel" pattern="[0-9]" maxLength="10" placeholder="Phone number" value={phone} 
                    onChange={(e) => {
                      setPhone(e.target.value)
                      if(e.target.value.length === 10){
                        checkContact();
                      }
                      }} 
                      required
                      disabled={PhoneDisabled}
                       />
                  </div>
                  {confirmotp === true && submitDisabled === false?
                  <div style={{display: 'flex', margin:"10px 40px"}}>
                  <input style={{width:"250px"}} type='text' 
                  value={otp} 
                  maxLength='6'
                  onChange={(e) => {
                    setOtp(e.target.value)
                    if(e.target.value.length > 0 && e.target.value.length < 6) {
                      setError("Please enter your 6 digit OTP");
                    }else if(e.target.value.length === 6){
                      setError("");
                    }
                  }} 
                  placeholder='Please enter your 6 digit OTP'/>  
                  {otp.length===6?<button className="tickbutton" type='button' onClick={onSendOtp} 
                  style={otp.length===6?{border: '0',borderRadius:'0',padding:'0',color:'#f1813a',fontSize: '22px',fontWeight: '600'}:{}}>
                    <FaRegArrowAltCircleRight>Validate OTP & Submit</FaRegArrowAltCircleRight>
                  </button>:null}
                    </div> :null}
                    <p style={error?{color: 'red', paddingTop: '5px'}: {color: 'green',  paddingTop: '5px'}}>{msg}{error}</p>
                    {confirmotp === false && submitDisabled === false ?
                      <div className="inputWrapper">
                      <button onClick={onSignInSubmit} className='send' style={disableOTPButton?{background:'#F48120'}: {background: '#c6c6c6', border: '1px solid #c6c6c6', color: '#333'}} disabled={disableOTPButton ? false:true} >Send OTP</button>
                      </div>
                     :null}
                  <div id="recaptcha-container"></div>
                  {submitDisabled?<div className="inputWrapper">
                    <button onclick="return gtag_report_conversion('https://delhi.narayanacoachingcenters.com/')" className='send' type="submit">Submit</button>
                  </div>:null}
                </form>
                            </div>
                            {/* <ul>
              <li> Experienced and Qualified Faculty.</li>
              <li> Well researched study material. </li>
              <li>Performance-oriented Approach.</li>
              <li> One to one doubt classes. </li>
              <li> Regular mock tests & test series. </li>
              <li> Detailed performance feedback. </li>
            </ul> */}
                        </div>
                    </div>
                </div>
            </section>

            <section id="video">
        <div className="container-fluid">
        <div className="row" style={{textAlign:"center", paddingBottom:"45px"}}>
            <div className="col-12">
            <h2 className='heading'>#ZidSafaltaKi</h2>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/A0r3cdzELCg?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>            </div>
          </div>
        </div>
      </section>

            <NACST />

            <section id='courses'>
                <div className="container-fluid webCourses">
                    <div className="row pdtb" style={{ padding: "45px 80px" }}>
                        {/* <div className="bgCourses">
                        <img src={backgroundImage} />
                    </div> */}
                        <div className="col-12">
                            <h2 className="heading" style={{ marginBottom: "40px" }}>Courses for NEET online coaching</h2>
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='One-Year Classroom Programme (OYCP)' content='This is an integrated course which consists of 480 course hours and is divided into 4 units followed by classroom interaction, phase tests, feedback via micro-level analysis and review classes based on the pattern of NEET /AIIMS along with Board examination.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='Two-Year Integrated Classroom Programme(TYCP)' content='This is a 700‐hours integrated programme comprising 8 units. It is divided into two academic sessions (4 units in each session). In the first academic session the entire syllabus of class XI is covered integrating the level of the board. The second session will be thoroughly preparing for their XII Board examination as well as NEET/AIIMS.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='Target One Year Classroom Programme (TOYCP)' content='This comprehensive course includes classroom interaction of 550 hours. The course is divided into 8 units and covers the entire syllabus of NEET / AIIMS with surprise tests and weekly tests included. Feedback is provided through micro-level analysis.' />
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='Test And Discussion Programme (TAD)' content='This course provides students with an opportunity to assess their level of preparation.This programme comprises 30 tests, out of which 16 are part syllabus tests and after every 2-part tests, there is a combined test on all the 2 test topics covered based on NEET/AIIMS.' />
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='Finishing Edge (CRASH COURSE FOR NEET)' content='In this crash course students receive extensive training for the NEET. They will be provided in-depth classroom teaching to emphasize on all the fundamentals / concepts. The aim of the course is to cover the entire syllabus in a fast-track and it is meant for XII Appearing as well as XII Pass students.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='Rank Promoting Test Series (RPTS) For XII Pass students' content='In this test series the students will prepare for the medical entrance examination with a focus on NEET/AIIMS. The student will be prepared for the test and it will be followed by a thorough discussion of 3 hours (45 minute for each subject), in which all questions of the test will be discussed in detail.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='Rank Promoting Test Series (RPTS) For Xll studying students' content='The initiation of the test series begins just after XII Board Exams.The student will be prepared for the test and it will be followed by a thorough discussion of 3 hours (45 minute for each subject), in which all questions of the test will be discussed in detail.' />
                        </div>
                        {/* <div className="col-6" style={{ zIndex: "11", background:"aliceblue" }}>
                        <CoursesCard title='Finishing Edge Crash Course for JEE (Main) & IP Univ. CET*' content='This programme helps students to improve their All India Rank. It consists of 150 course hours followed by doubt removal classes, and tips to crack JEE (Main) & IP Univ. Students enrolled in this programme are eligible for pattern tests for JEE (Main) & IP Univ. CET.' />
                    </div> */}
                    </div>
                </div>
                <div className="container-fluid mobCourses">
                    <div className="row pdtb">
                        {/* <div className="bgCourses">
                        <img src={backgroundImage} />
                    </div> */}
                        <div className="col-12">
                            <h2 className="heading" style={{ marginBottom: "40px" }}>Courses for NEET online coaching</h2>
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='Two-Year Integrated Classroom Programme (TYICP)' content='This programme prepares the students for JEE Main & JEE Advanced  that comprises more than 700 hours which  is divided into 5 phases, covering topics of both CBSE and JEE level.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='APEX Two-Year Integrated Classroom Programme (AICP)' content='This programme prepares the students for JEE Main & JEE Advanced that comprises more than 14000 hours that is divided into 5 phases, covering topics of both CBSE and JEE level.' />
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='SPARK Two-Year Integrated Classroom Programme (STYCP)' content='This programme aims to encourage competition among students with innovative skills, commitment and ability to transform the best in them. Every individual is made to realize his/her potential and is facilitated to achieve what is considered unachievable.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='One-Year Integrated Classroom Programme (OYICP)' content='The program aims to prepare students for JEE Main and JEE Advanced to bring out the desired performance that comprises 480 hours and further segregated into 4 phases that includes training, practice sessions and revision classes.' />
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='Extended One-Year Classroom Programme (EOYCP)' content='This programme prepares students  for JEE Main and Advanced, comprising 540 course hours divided into 4 phases which includes training, test for each phase, practice sessions and revision classes.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='Condensed One-Year Integrated Classroom Programme (COYICP)' content='This programme is designed for the students who are late or got delayed.The course is divided into 2 phases which includes training, practice sessions and revision classes. The students are also eligible for Narayana All India Test Series which helps them to evaluate their performance and compete on National level.' />
                        </div>
                        <div className="col-6" style={{ background: "aliceblue" }}>
                            <CoursesCard title='Special Rank Promoting Programme (SRPP)' content='This programme helps students to improve their All India Rank. It consists of 180 course hours followed by doubt removal classes, developing skill improvement, time management and on how to crack the JEE (Main). The students enrolled with this course are also eligible for  Narayana All India Test Series.' />
                        </div>
                        <div className="col-6">
                            <CoursesCard title='Finishing Edge Crash Course for JEE (Main) & IP Univ. CET*' content='This programme helps students to improve their All India Rank. It consists of 150 course hours followed by doubt removal classes, and tips to crack JEE (Main) & IP Univ. Students enrolled in this programme are eligible for pattern tests for JEE (Main) & IP Univ. CET.' />
                        </div>
                    </div>
                </div>
            </section>

            <section id='results'>
                <div className="webResults container-fluid">
                    <div className="row pdtb">
                        <div className="col-12">
                            <h2 className="heading" style={{ marginBottom: "20px" }}> Join the legacy </h2>
                            <img src={video} />
                        </div>
                    </div>
                    {/* <div className="bgFeedback row pdtb">
                    <div className="col-12">
                        <img src={backgroundImage} />
                    </div>
                </div> */}
                    <div className="feedback row pdtb">
                        <div className="col-12" style={{ display: "inline-flex" }}>
                            <img style={{ margin: "0 30px 20px 0", width: "150px" }} src={orangequote} />
                            <h2 className='feedbackHeading' style={{ fontWeight: "600" }}>Feedback from <br /> our students</h2>
                        </div>
                        <div className="col-5">
                            <div className="feedbackContent" style={{ height: "380px", padding: "70px" }}>
                                <img src={quotesmall} />  <p style={{ marginTop: "20px" }}> Narayana has not only helped me clear IIT-Mains with top percentile but also assisted me in scoring 90+
                    percentage in my board exams. Grateful to all the teachers and management of Narayana. </p>
                                <div className="feedbackStudent"> <img src={user} /> <p style={{ fontWeight: "600" }}> - Suresh Panwar </p> </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="feedbackContent" style={{ width: "400px" }}>
                                <img src={quotesmall} />  <p style={{ marginTop: "5px" }}> Studying at Narayana for past one year and have thoroughly enjoyed the friendly nature of faculties towards students. Great place to study. </p>
                                <div className="feedbackStudent"> <img src={user} /> <p style={{ fontWeight: "600" }}> - Rajat Sharma </p> </div>
                            </div>
                            <div className="feedbackContent" style={{ width: "400px" }}>
                                <img src={quotesmall} />  <p style={{ marginTop: "5px" }}> I owe my success to Narayana Delhi. </p>
                                <div className="feedbackStudent"> <img src={user} /> <p style={{ fontWeight: "600" }}> - Suraj Yadav </p> </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobResults container-fluid">
                    <div className="row pdtb">
                        <div className="col-12">
                            <h2 className="heading" style={{ marginBottom: "20px" }}> Join the legacy </h2>
                            <img src={video} alt="video" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="feedback row pdtb">
                        <div className="col-12" style={{ display: "inline-flex", marginTop: "40px" }}>
                            <img style={{ margin: "0 50px 20px 0", width: "50px", height: "50px" }} src={orangequote} />
                            <h2 className='feedbackHeading' style={{ fontWeight: "600" }}>Feedback from our students</h2>
                        </div>
                        <div className="col-4">
                            <div className="feedbackContent">
                                <img src={quotesmall} />
                                <p style={{ marginTop: "20px" }}> Narayana has not only helped me clear IIT-Mains with top percentile but also assisted me in scoring 90+
                    percentage in my board exams. Grateful to all the teachers and management of Narayana. </p>
                                <div className="feedbackStudent"> <img src={user} /> <p style={{ fontWeight: "600" }}> - Suresh Panwar </p> </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="feedbackContent">
                                <img src={quotesmall} />
                                <p style={{ marginTop: "5px" }}> Studying at Narayana for past one year and have thoroughly enjoyed the friendly nature of faculties towards students. Great place to study. </p>
                                <div className="feedbackStudent"> <img src={user} /> <p style={{ fontWeight: "600" }}> - Rajat Sharma </p> </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="feedbackContent">
                                <img src={quotesmall} />
                                <p style={{ marginTop: "5px" }}> I owe my success to Narayana Delhi. </p>
                                <div className="feedbackStudent"> <img src={user} /> <p style={{ fontWeight: "600" }}> - Suraj Yadav </p> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='contact'>
                <div className="container-fluid">
                    <div className="row pdtb">
                        <div className="col-6">
                            <img src={iit} />
                            <div className="cardContent">
                                <p>Unlock your true potential</p>
                                <a style={{ fontWeight: "600" }} target="_blank" href="/JEE">Learn More</a>
                            </div>
                        </div>
                        <div className="col-6">
                            <img src={foundation} />
                            <div className="cardContent">
                                <p>Your gateway to quality learning</p>
                                <a style={{ fontWeight: "600" }} target="_blank" href="/FoundationCoaching">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>

    );
}
export default Neet;
